<template>
  <div
    class="relative w-full h-full flex flex-col"
  >
    <div
      class="relative w-full h-full"
      :class="{
        'shadow-lg border border-gray-200 bg-white': props.shadow,
        'rounded-md overflow-hidden': props.rounded,
        'aspect-video': props.aspectVideo,
      }"
    >
      <div
        v-if="props.resource.mediaType.startsWith('image') && !loaded"
        class="absolute inset-0 bg-slate-200 animate-pulse"
      />
      <div
        class="relative z-10"
      >
        <NuxtPicture
          v-if="props.resource.mediaType.startsWith('image')"
          :src="(props.resource as ImageResource).src"
          :alt="(props.resource as ImageResource).alt ?? ''"
          :title="(props.resource as ImageResource).title"
          :sizes="(props.resource as ImageResource).options?.sizes ?? ''"
          :loading="(props.resource as ImageResource).options?.loading ?? 'lazy'"
          @load="() => loaded = true"
          @error="() => loaded = true"
          @click="magnifyImage"
          class="cursor-zoom-in"
          :class="{
            'cursor-mag': props.resource.options?.['magnify']
          }"
        />
        <video
          v-else-if="props.resource.mediaType.startsWith('video')"
          :src="(props.resource as VideoResource).src"
          :alt="(props.resource as VideoResource).alt ?? ''"
          :title="(props.resource as VideoResource).title"
          :controls="(props.resource as VideoResource).options?.controls ?? false"
          :autoplay="(props.resource as VideoResource).options?.autoplay ?? false"
          :muted ="(props.resource as VideoResource).options?.muted ?? true"
          :loop="(props.resource as VideoResource).options?.loop ?? true"
          class="object-cover w-full h-full"
        />
        <div
          v-else-if="props.resource.mediaType.startsWith('text')"
          class="m-2"
        >
          <span>{{ textData }}</span>
        </div>
        <div
          v-else
          class="m-2"
        >
          <font-awesome-icon
            icon="file-binary"
            :title="props.resource.mediaType"
            class="text-2xl"
          />
        </div>
      </div>
    </div>
    <p
      v-if="props.caption"
      class="b2 text-center mt-2 text-slate-900"
    >
      {{ props.caption }}
    </p>

    <teleport
      v-if="magnified"
      to="body"
    >
      <div
        class="fixed z-30 cursor-zoom-out inset-0 flex justify-center"
        @click="magnified = false"
      >
        <div
          class="magnified m-4 tablet:m-10 laptop:m-32"
        >
          <NuxtPicture
            :src="(props.resource as ImageResource).src"
            :alt="(props.resource as ImageResource).alt ?? ''"
            :title="(props.resource as ImageResource).title"
            :sizes="(props.resource as ImageResource).options?.sizes ?? ''"
            :loading="(props.resource as ImageResource).options?.loading ?? 'lazy'"
          />
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup lang="ts">
import type {Image, Resource, Video} from "~/utils/types";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

const charon = useCharon(true)
const config = useRuntimeConfig()

interface ImageOptions {
  sizes: string
  loading?: 'lazy' | 'eager'
  magnify?: boolean
}

interface VideoOptions {
  controls?: boolean
  autoplay?: boolean
  muted?: boolean
  loop?: boolean
}

type ImageResource = (Image & { options?: ImageOptions, mediaType: string })
type VideoResource = (Video & { options?: VideoOptions, mediaType: string })
type AnyResource = (Resource & { options?: object, mediaType: string })

const props = defineProps<{
  resource: ImageResource | VideoResource | AnyResource
  caption?: string // uses title as default
  rounded?: boolean
  shadow?: boolean
  aspectVideo?: boolean
}>()

const loaded = ref(false)

// text data can be shown inline
const textData = ref("")
if (props.resource.mediaType.startsWith("text")) {
  $fetch<Blob>(props.resource.src)
    .then(res => res.text())
    .then(text => {
      if (text.length < 100) {
        textData.value = text
        return
      }
      // truncate string
      textData.value = text.slice(0, 100) + '...'
      loaded.value = true
    })
}

const magnified = ref(false)
const magnifyImage = () => {
  if (!(props.resource.options as ImageOptions).magnify) {
    return
  }
  magnified.value = true
}
</script>

<style scoped lang="postcss">
:deep(img) {
  @apply w-full h-full object-cover
}
:deep(.magnified img) {
  @apply w-full h-full object-contain rounded overflow-hidden
}
</style>
